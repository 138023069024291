import baseAPI from "./baseAPI";

const nodeAPI = {
  list() {
    return baseAPI.get('/api/nodes')
  },
  get(uri) {
    return baseAPI.get(uri);
  },
  create(formData = {}) {
    switch (formData.content_type) {
      case "question_node":
        return baseAPI.post('/api/question_nodes', formData);
      case "sub_tree_node":
        return baseAPI.post('/api/sub_tree_nodes', formData);
      case "text_node":
        return baseAPI.post('/api/text_nodes', formData);
      default:
        return undefined
    }
  },
  put(uri, formData) {
    return baseAPI.put(uri, formData);
  },
  patch(uri, formData) {
    return baseAPI.patch(uri, formData);
  },
};

export default nodeAPI;
