import baseAPI from "./baseAPI";
import {listTreePublications} from "../features/trees/treesSlice";

const treeAPI = {
  list() {
    return baseAPI.get('/api/trees', {archived: false})
  },
  listSubtrees(id) {
    return baseAPI.get(`/api/trees/${id}/subtree_paths`, )
  },
  get(uri) {
    return baseAPI.get(uri);
  },
  create(formData = {}) {
    return baseAPI.post('/api/trees', formData);
  },
  validate(id, entity = 'trees') {
    return baseAPI.post(`/api/${entity}/${id}/validate`);
  },
  publish(uri) {
    return baseAPI.post('/api/tree_publications', {
      tree: uri,
    });
  },
  preview(uri, params) {
    const formData = {tree: uri};
    if (params) {
      formData.params = params;
    }
    return baseAPI.post('/api/tree_publications_preview', formData);
  },
  listTreePublications(uri) {
    return baseAPI.get('/api/list_tree_publications', {
      tree: uri,
    });
  },
  getComprehensiveFlowAutocomplete(id){
    return baseAPI.get(`/api/trees/${id}/comprehensive_flow_autocomplete`,{});
  },
  patch(uri, formData = {}) {
    return baseAPI.patch(uri, formData);
  },
  rollback(id, formData = {}) {
    return baseAPI.patch(`/api/trees/${id}/rollback`, formData);
  },
  put(uri, formData = {}) {
    return baseAPI.put(uri, formData);
  },
  addNode(formData = {}) {
    return baseAPI.post('/api/tree_nodes', formData);
  },
  delete(uri) {
    return baseAPI.delete(uri);
  }
};

export default treeAPI;
