import {createAsyncThunk, createEntityAdapter, createSelector, createSlice} from '@reduxjs/toolkit'
import {normalize} from "normalizr";
import {entityMetadataEntity} from "../../schemas";
import metadataAPI from "../../services/metadataAPI";

// Use the uri as ID
export const entityMetadataAdapter = createEntityAdapter({
  selectId: entity => entity['@id']
})

export const fetchEntityMetadata = createAsyncThunk("entityMetadata/fetchEntityMetadata", async (uri) => {
    const results = await metadataAPI.entityMetadata(uri);
    const normalized = normalize(results.data['hydra:member'], [entityMetadataEntity]);

    return normalized.entities;
  }
)

export const createEntityMetadata = createAsyncThunk("entityMetadata/createEntityMetadata", async (formData) => {
    // API doesn't support booleans
    if (typeof formData.metaValue === 'boolean') {
      formData.metaValue = formData.metaValue.toString()
    }

    const results = await metadataAPI.createEntityMetadata(formData);
    const normalized = normalize(results.data, entityMetadataEntity);

    return normalized.entities;
  }
)

export const patchEntityMetadata = createAsyncThunk("entityMetadata/patchEntityMetadata", async ({uri, formData}) => {
    // API doesn't support booleans
    if (typeof formData.metaValue === 'boolean') {
      formData.metaValue = formData.metaValue.toString()
    }

    const results = await metadataAPI.patch(uri, formData);
    const normalized = normalize(results.data, entityMetadataEntity);

    return normalized.entities;
  }
)

export const deleteEntityMetadata = createAsyncThunk("entityMetadata/deleteEntityMetadata", async (entity) => {
    await metadataAPI.delete(entity['@id']);
    return entity
  }
)

const entityMetadataSlice = createSlice({
  name: 'entityMetadata',
  initialState: entityMetadataAdapter.getInitialState(),
  reducers: {},
  extraReducers: {
    [createEntityMetadata.fulfilled]: (state, action) => {
      entityMetadataAdapter.addMany(state, action.payload.entityMetadataEntity ?? [])
    },
    [deleteEntityMetadata.fulfilled]: (state, action) => {
      entityMetadataAdapter.removeOne(state, action.payload['@id'])
    },
    [fetchEntityMetadata.fulfilled]: (state, action) => {
      entityMetadataAdapter.upsertMany(state, action.payload.entityMetadataEntity ?? [])
    },
    [patchEntityMetadata.fulfilled]: (state, action) => {
      entityMetadataAdapter.upsertMany(state, action.payload.entityMetadataEntity ?? [])
    },
    'questions/getQuestion/fulfilled': (state, action) => {
      entityMetadataAdapter.upsertMany(state, action.payload.entityMetadataEntity ?? [])
    },
  }
})

// export const {} = metadataSlice.actions

export default entityMetadataSlice.reducer

export const  selectEntityMetadata = uri => createSelector(
  (state) => state.entityMetadata.entities,
  (metadata) => Object.values(metadata).filter(_item => _item['entityUri'] === uri)
)

export const  checkSubjectQuestionMetadata = (ids) => createSelector(
  (state) => state.entityMetadata.entities,
  (metadata) => Object.values(metadata).filter(_item => {
     return  ids.includes(_item['id']) && 'question_with_subject' === _item['metaKey'] && 'true' === _item['metaValue']
    }
  )
)


