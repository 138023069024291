import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit'
import baseAPI from "../../services/baseAPI";
import {jws} from "jsrsasign";
import LocalStorageService from "../../services/storage/LocalStorageService";
import env from "@beam-australia/react-env";
import {normalize} from "normalizr";
import {UserEntity} from "../../schemas";

const userAdapter = createEntityAdapter({
  selectId: entity => entity.email
})

export const doLogin = createAsyncThunk("security/doLogin", async (formData) => {
    const result = await baseAPI.post('/authentication_token', formData)

    // Store accessToken (in localStorage)
    LocalStorageService.setToken(result.data)

    return parseAndValidateAccessToken(result.data.token)
  }
)

export const refreshAccessToken = async () => {
  const result = await baseAPI.post('/api/token/refresh', {
    refresh_token: LocalStorageService.getRefreshToken()
  })

  // Store accessToken (in localStorage)
  LocalStorageService.setToken(result.data)

  return result.data
}

export const handleTokenRefresh = createAsyncThunk("security/handleTokenRefresh", () => {
    return new Promise((resolve, reject) => {
      if (!LocalStorageService.getRefreshToken()) {
        reject()
      }

      refreshAccessToken().then(data => {
        resolve(parseAndValidateAccessToken(data.token))
      }).catch(() => {
        reject()
      })
    })
  }
)

export const fetchUsers = createAsyncThunk("security/fetchUsers", async () => {
    const results = await baseAPI.get('/api/users')
    const normalized = normalize(results.data['hydra:member'], [UserEntity]);

    return normalized.entities;
  }
)

export const getToken = () => {
  const accessToken = LocalStorageService.getAccessToken()

  if (accessToken) {
    return parseAndValidateAccessToken(accessToken)
  }

  return false
}

const parseAndValidateAccessToken = (accessToken) => {
  if (jws.JWS.verifyJWT(accessToken, env('JWT_PUBLIC_KEY'), {alg: ["RS256"]})) {
    const token = jws.JWS.parse(accessToken)

    return token.payloadObj
  }

  return false
}

const securitySlice = createSlice({
  name: 'security',
  initialState: {
    isLoggedIn: false,
    token: {},
    users: userAdapter.getInitialState(),
  },
  reducers: {
    setToken: {
      reducer(state, action) {
        state.isLoggedIn = true
        state.token = action.payload
      },
    },
    logOut: {
      reducer(state, action) {
        LocalStorageService.clearToken()

        state.isLoggedIn = false
        state.token = {}
      },
    }
  },
  extraReducers: {
    [doLogin.fulfilled]: (state, action) => {
      state.isLoggedIn = true
      state.token = action.payload
    },
    [handleTokenRefresh.fulfilled]: (state, action) => {
      state.isLoggedIn = true
      state.token = action.payload
    },
    [handleTokenRefresh.rejected]: (state, action) => {
      LocalStorageService.clearToken()

      state.isLoggedIn = false
      state.token = {}
    },
    [fetchUsers.fulfilled]: (state, action) => {
      userAdapter.upsertMany(state.users, action.payload.users)
    }
  }
})

export const {setToken, logOut} = securitySlice.actions

export default securitySlice.reducer

// Rename the exports for readability in component usage
export const {
  selectById: selectUserById,
  selectIds: selectUserIds,
  selectEntities: selectUserEntities,
  selectAll: selectAllUsers,
  selectTotal: selectTotalUsers
} = userAdapter.getSelectors(state => state.security.users)
