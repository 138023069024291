import baseAPI from "./baseAPI";

const questionAPI = {
  list() {
    return baseAPI.get('/api/questions')
  },
  create(formData = {}) {
    switch (formData.type) {
      case 'choice_question':
        return baseAPI.post('/api/choice_questions', formData)
      case 'yes_no_question':
        return baseAPI.post('/api/yes_no_questions', formData)
      case 'short_answer':
        return baseAPI.post('/api/text_questions', formData)
      case 'long_answer':
        return baseAPI.post('/api/text_questions', {
          ...formData,
          textArea: true,
        })
      case 'date_question':
        return baseAPI.post('/api/date_questions', formData)
      case 'file_upload_question':
        return baseAPI.post('/api/file_upload_questions', formData)
      default:
        return undefined
    }
  },
  createChoice(formData = {}) {
    return baseAPI.post('/api/choices', formData)
  },
  deleteChoice(uri) {
    return baseAPI.delete(uri);
  },
  get(uri) {
    return baseAPI.get(uri)
  },
  patch(uri, formData = {}) {
    return baseAPI.patch(uri, formData)
  },
  put(uri, formData = {}) {
    return baseAPI.put(uri, formData)
  }
}

export default questionAPI
