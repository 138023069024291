import {createAsyncThunk, createEntityAdapter, createSelector, createSlice} from '@reduxjs/toolkit'
import {normalize} from "normalizr";
import {nodeEntity} from "../../schemas";
import nodeAPI from "../../services/nodeAPI";

// Use the uri as ID
const nodesAdapter = createEntityAdapter()

export const fetchNodes = createAsyncThunk(
  "nodes/fetchNodes",
  async () => {
    const results = await nodeAPI.list();
    const normalized = normalize(results.data['hydra:member'], [nodeEntity]);

    return normalized.entities;
  }
)

export const getNode = createAsyncThunk("nodes/getNode", async ({uri}) => {
    const results = await nodeAPI.get(uri);
    const normalized = normalize(results.data, nodeEntity);

    return normalized.entities;
  }
)

export const createNode = createAsyncThunk("nodes/createNode", async (formData) => {
    const results = await nodeAPI.create(formData);
    const normalized = normalize(results.data, nodeEntity);

    return normalized.entities;
  }
)

export const patchNode = createAsyncThunk("nodes/patchNode", async ({uri, formData}) => {
    const results = await nodeAPI.patch(uri, formData);
    const normalized = normalize(results.data, nodeEntity);

    return normalized.entities;
  }
)

export const addQuestion = createAsyncThunk("nodes/addQuestion", async (payload) => {
    const {node, question} = payload

    const formData = {
      ...node,
      questions: node.questions.map(q => q)
    }

    formData.questions.push(question)

    const results = await nodeAPI.put(node['@id'], formData);
    const normalized = normalize(results.data, nodeEntity);

    return normalized.entities;
  }
)

export const removeQuestion = createAsyncThunk("nodes/removeQuestion", async ({node, question}) => {
    const formData = {
      ...node,
      questions: node.questions.filter(q => q !== question)
    }

    const results = await nodeAPI.put(node['@id'], formData);
    const normalized = normalize(results.data, nodeEntity);

    return normalized.entities;
  }
)

const nodesSlice = createSlice({
  name: 'nodes',
  initialState: nodesAdapter.getInitialState(),
  reducers: {},
  extraReducers: {
    [fetchNodes.fulfilled]: (state, action) => {
      nodesAdapter.upsertMany(state, action.payload.nodes ?? [])
    },
    [patchNode.fulfilled]: (state, action) => {
      nodesAdapter.upsertMany(state, action.payload.nodes ?? [])
    },
    [createNode.fulfilled]: (state, action) => {
      nodesAdapter.addMany(state, action.payload.nodes)
    },
    [getNode.fulfilled]: (state, action) => {
      nodesAdapter.upsertMany(state, action.payload.nodes)
    },
    [addQuestion.fulfilled]: (state, action) => {
      nodesAdapter.upsertMany(state, action.payload.nodes)
    },
    [removeQuestion.fulfilled]: (state, action) => {
      nodesAdapter.upsertMany(state, action.payload.nodes)
    }
  }
})

// export const {} = nodesSlice.actions

export default nodesSlice.reducer

export const selectNodeByUri = uri => createSelector(
  (state) => state.nodes.entities,
  (nodes) => Object.values(nodes).find((node) => node['@id'] === uri)
)

// Rename the exports for readability in component usage
export const {
  selectById: selectNodeById,
  selectIds: selectNodeIds,
  selectEntities: selectNodeEntities,
  selectAll: selectAllNodes,
  selectTotal: selectTotalNodes
} = nodesAdapter.getSelectors(state => state.nodes)
