import React, {Component} from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import './scss/style.scss';
import Authentication from "./views/security/Authentication";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

class App extends Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Authentication>
              <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
            </Authentication>
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
