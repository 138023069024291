import {combineReducers} from 'redux'
import appReducer from '../features/app/appSlice'
import treesReducer from '../features/trees/treesSlice'
import treePublicationsReducer from '../features/trees/treePublicationsSlice'
import nodesReducer from '../features/nodes/nodesSlice'
import questionsReducer from '../features/questions/questionsSlice'
import choicesReducer from '../features/choices/choicesSlice'
import treeNodesReducer from '../features/treeNodes/treeNodesSlice'
import securityReducer from '../features/security/securitySlice'
import metadataReducer from '../features/metadata/metadataSlice'
import entityMetadataReducer from '../features/metadata/entityMetadataSlice'
import mediaObjectReducer from '../features/metadata/mediaObjectSlice'

export default combineReducers({
  app: appReducer,
  trees: treesReducer,
  treePublications: treePublicationsReducer,
  nodes: nodesReducer,
  questions: questionsReducer,
  choices: choicesReducer,
  treeNodes: treeNodesReducer,
  security: securityReducer,
  metadata: metadataReducer,
  entityMetadata: entityMetadataReducer,
  mediaObjects: mediaObjectReducer,
})
