import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import treeAPI from "../../services/treeAPI";
import {normalize} from "normalizr";
import {treePublication} from "../../schemas";

const treePublicationsAdapter = createEntityAdapter()



export const listTreePublications = createAsyncThunk("trees/listTreePublications", async ({uri}) => {
    const results = await treeAPI.listTreePublications(uri);
    const normalized = normalize(results.data['hydra:member'], [treePublication]);
    console.log(results, normalized);
    return normalized.entities;
  }
)
const treePublicationsSlice = createSlice({
  name: 'treePublications',
  initialState: treePublicationsAdapter.getInitialState(),
  reducers: {},
  extraReducers: {
    [listTreePublications.fulfilled]: (state, action) => {
      console.log(action)
      treePublicationsAdapter.upsertMany(state, action.payload.treePublications ?? [])
    }
  }
});


export default treePublicationsSlice.reducer

// Rename the exports for readability in component usage
export const {
} = treePublicationsAdapter.getSelectors(state => state.treePublications)
