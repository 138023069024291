import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit'
import {normalize} from "normalizr";
import {mediaObjectEntity} from "../../schemas";
import metadataAPI from "../../services/metadataAPI";

// Use the uri as ID
export const mediaObjectAdapter = createEntityAdapter({
  selectId: entity => entity['@id']
})

export const uploadFile = createAsyncThunk("mediaObject/uploadFile", async (formData) => {
    const results = await metadataAPI.upload(formData);
    const normalized = normalize(results.data, mediaObjectEntity);

    return normalized.entities;
  }
)

export const getMediaObject = createAsyncThunk("mediaObject/getMediaObject", async (uri) => {
    const results = await metadataAPI.get(uri);
    const normalized = normalize(results.data, mediaObjectEntity);

    return normalized.entities;
  }
)

const mediaObjectSlice = createSlice({
  name: 'mediaObject',
  initialState: mediaObjectAdapter.getInitialState(),
  reducers: {},
  extraReducers: {
    [uploadFile.fulfilled]: (state, action) => {
      mediaObjectAdapter.addMany(state, action.payload.mediaObjects ?? [])
    },
    [getMediaObject.fulfilled]: (state, action) => {
      mediaObjectAdapter.upsertMany(state, action.payload.mediaObjects)
    },
  }
})

// export const {} = metadataSlice.actions

export default mediaObjectSlice.reducer

// Rename the exports for readability in component usage
export const {
  selectById: selectMediaObjectById,
  selectIds: selectMediaObjectIds,
  selectEntities: selectMediaObjectEntities,
  selectAll: selectAllMediaObjects,
  selectTotal: selectTotalMediaObjects
} = mediaObjectAdapter.getSelectors(state => state.mediaObjects)
