import baseAPI from "./baseAPI";

const metadataAPI = {
  create(formData) {
    return baseAPI.post('/api/metadata', formData)
  },
  createEntityMetadata(formData) {
    return baseAPI.post('/api/entity_metadata', formData)
  },
  delete(uri) {
    return baseAPI.delete(uri)
  },
  entityMetadata(uri) {
    return baseAPI.get('/api/entity_metadata', {'entityUri': uri})
  },
  get(uri) {
    return baseAPI.get(uri)
  },
  list() {
    return baseAPI.get('/api/metadata')
  },
  patch(uri, formData = {}) {
    return baseAPI.patch(uri, formData);
  },
  upload(formData = {}) {
    return baseAPI.post('/api/media_objects', formData);
  },
};

export default metadataAPI;
