import {createAsyncThunk, createEntityAdapter, createSelector, createSlice} from '@reduxjs/toolkit'
import {selectQuestionById} from "../questions/questionsSlice";
import questionAPI from "../../services/questionAPI";
import {normalize} from "normalizr";
import {choiceEntity} from "../../schemas";

// Use the uri as ID
export const choicesAdapter = createEntityAdapter({
  selectId: entity => entity['@id']
})

export const patchChoice = createAsyncThunk(
  "choices/patchChoice",
  async (payload) => {
    const {uri, formData} = payload

    const results = await questionAPI.patch(uri, formData);
    const normalized = normalize(results.data, choiceEntity);

    return normalized.entities;
  }
)

const choicesSlice = createSlice({
  name: 'choices',
  initialState: choicesAdapter.getInitialState(),
  reducers: {
    updateChoice(state, action) {
      choicesAdapter.upsertOne(state, action.payload)
    },
    updateChoices(state, action) {
      choicesAdapter.upsertMany(state, action.payload)
    }
  },
  extraReducers: {
    'questions/getQuestion/fulfilled': (state, action) => {
      const questions = Object.values(action.payload.questions)
      choicesAdapter.upsertMany(state, questions[0].choices ?? [])
    },
    [patchChoice.fulfilled]: (state, action) => {
      choicesAdapter.upsertMany(state, action.payload.choices)
    },
  }
})

export const {updateChoice, updateChoices} = choicesSlice.actions

export default choicesSlice.reducer

// Rename the exports for readability in component usage
export const {
  selectById: selectChoiceById,
  selectIds: selectChoiceIds,
  selectEntities: selectChoiceEntities,
  selectAll: selectAllChoices,
  selectTotal: selectTotalChoices
} = choicesAdapter.getSelectors(state => state.choices)

export const selectChoicesByQuestionUri = questionUri =>
  createSelector(
    [
      state => selectQuestionById(state, questionUri), // select the current article
      state => state.choices.ids.map(id => state.choices.entities[id]) // this is the same as selectAllComments
    ],
    (question, choices) => {
      if (question.hasOwnProperty('choices') === false) {
        return []
      }

      choices.sort((a, b) => a.sortOrder - b.sortOrder)

      // return the choices for the given question only
      return Object.keys(choices)
        .map(c => choices[c])
        .filter(choice => question.choices.map(c => c['@id']).includes(choice['@id']));
    }
  );
