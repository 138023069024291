import {createAsyncThunk, createEntityAdapter, createSelector, createSlice} from '@reduxjs/toolkit'
import {normalize} from "normalizr";
import {metadataEntity} from "../../schemas";
import metadataAPI from "../../services/metadataAPI";

// Use the uri as ID
export const metadataAdapter = createEntityAdapter({
  selectId: entity => entity['@id']
})

export const fetchMetadata = createAsyncThunk("metadata/fetchMetadata", async () => {
    const results = await metadataAPI.list();
    const normalized = normalize(results.data['hydra:member'], [metadataEntity]);

    return normalized.entities;
  }
)

export const createMetadata = createAsyncThunk("metadata/createMetadata", async (formData) => {
    const results = await metadataAPI.create(formData);
    const normalized = normalize(results.data, metadataEntity);

    return normalized.entities;
  }
)

export const patchMetadata = createAsyncThunk("metadata/patchMetadata", async ({uri, formData}) => {
    const results = await metadataAPI.patch(uri, formData);
    const normalized = normalize(results.data, metadataEntity);

    return normalized.entities;
  }
)

export const deleteMetadata = createAsyncThunk("metadata/deleteMetadata", async ({metadata}) => {
    await metadataAPI.delete(metadata['@id']);

    return metadata
  }
)

const metadataSlice = createSlice({
  name: 'metadata',
  initialState: metadataAdapter.getInitialState(),
  reducers: {},
  extraReducers: {
    [fetchMetadata.fulfilled]: (state, action) => {
      metadataAdapter.upsertMany(state, action.payload.metadata ?? [])
    },
    [patchMetadata.fulfilled]: (state, action) => {
      metadataAdapter.upsertMany(state, action.payload.metadata ?? [])
    },
    [createMetadata.fulfilled]: (state, action) => {
      metadataAdapter.addMany(state, action.payload.metadata)
    },
    [deleteMetadata.fulfilled]: (state, action) => {
      metadataAdapter.removeOne(state, action.payload['@id'])
    },
  }
})

// export const {} = metadataSlice.actions

export default metadataSlice.reducer

// Rename the exports for readability in component usage
export const {
  selectById: selectMetadataById,
  selectIds: selectMetadataIds,
  selectEntities: selectMetadataEntities,
  selectAll: selectAllMetadata,
  selectTotal: selectTotalMetadata
} = metadataAdapter.getSelectors(state => state.metadata)

export const selectMetadataByKey = keyName => createSelector(
  (state) => state.metadata.entities,
  (metadata) => Object.values(metadata).find((_item) => _item['keyName'] === keyName)
)
