import {schema} from "normalizr";

// definite normalizr entity schemas
export const treeEntity = new schema.Entity("trees");
export const treePublication = new schema.Entity("treePublications");
export const nodeEntity = new schema.Entity("nodes");
export const treeNodeEntity = new schema.Entity("treeNodes");
export const entityMetadataEntity = new schema.Entity("entityMetadataEntity");
export const choiceEntity = new schema.Entity("choices", {
  'metadata': [entityMetadataEntity],
});
export const questionEntity = new schema.Entity("questions", {
  'metadata': [entityMetadataEntity],
});
export const metadataEntity = new schema.Entity("metadata");
export const mediaObjectEntity = new schema.Entity("mediaObjects");
export const UserEntity = new schema.Entity("users");
