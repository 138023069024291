import {createAsyncThunk, createEntityAdapter, createSelector, createSlice} from '@reduxjs/toolkit'
import {selectTreeById} from "../trees/treesSlice";
import treeAPI from "../../services/treeAPI";
import {normalize} from "normalizr";
import {treeNodeEntity} from "../../schemas";
import {patchQuestion} from "../questions/questionsSlice";

// Use the uri as ID
export const treeNodesAdapter = createEntityAdapter({
  selectId: entity => entity['@id']
})

export const patchTreeNode = createAsyncThunk("treeNodes/patchTreeNode", async ({uri, formData}) => {
    const results = await treeAPI.patch(uri, formData);
    const normalized = normalize(results.data, treeNodeEntity);
    return normalized.entities;
  }
)

export const putTreeNode = createAsyncThunk("treeNodes/putTreeNode", async ({uri, formData}) => {
    const results = await treeAPI.put(uri, formData);
    const normalized = normalize(results.data, treeNodeEntity);
    return normalized.entities;
  }
)

export const validateTreeNode = createAsyncThunk("treeNodes/validateTreeNode", async (id) => {
    const results = await treeAPI.validate(id, 'tree_nodes');
    const normalized = normalize(results.data, treeNodeEntity);

    return normalized.entities;
  }
)

const treeNodesSlice = createSlice({
  name: 'treeNodes',
  initialState: treeNodesAdapter.getInitialState(),
  reducers: {
    updateTreeNodes(state, action) {
      treeNodesAdapter.upsertMany(state, action.payload)
    }
  },
  extraReducers: {
    'trees/getTree/fulfilled': (state, action) => {
      const trees = Object.values(action.payload.trees)
      treeNodesAdapter.upsertMany(state, trees[0].treeNodes ?? [])
    },
    'trees/addNode/fulfilled': (state, action) => {
      treeNodesAdapter.addMany(state, action.payload.treeNodes)
    },
    'trees/deleteTreeNode/fulfilled': (state, action) => {
      const treeNode = action.payload.treeNode['@id'];
      if (state.ids.includes(treeNode)) {
        const choiceRoutings = state.entities[treeNode].choiceRoutings;
        choiceRoutings.forEach((routing) => {
          const routeToTreeNode = routing.routeToTreeNode;
          if (state.ids.includes(routeToTreeNode) && !state.entities[routeToTreeNode].visible) {
            treeNodesAdapter.removeOne(state, routeToTreeNode);
          }
        })
      }
      treeNodesAdapter.removeOne(state, treeNode);
    },
    [patchTreeNode.fulfilled]: (state, action) => {
      treeNodesAdapter.upsertMany(state, action.payload.treeNodes)
    },
    [patchTreeNode.rejected]: (state, action) => {
      console.warn('treeNodes/patchTreeNode could cause error if so change to putTreeNode for this call');
    },
    [putTreeNode.fulfilled]: (state, action) => {
      treeNodesAdapter.upsertMany(state, action.payload.treeNodes)
    },
    [validateTreeNode.fulfilled]: (state, action) => {
      treeNodesAdapter.upsertMany(state, action.payload.treeNodes)
    },
    [patchQuestion.fulfilled]: (state, action) => {
      const questions = Object.values(action.payload.questions);
      questions.forEach((question => {

        if (question.params?.delete_choice_routing && question.params?.choice_routing_tree_node && question.params?.delete_next_tree_node) {
          const deleteChoiceRouting = `/api/choice_routings/${question.params.delete_choice_routing}`;
          const choiceRoutingTreeNode = `/api/tree_nodes/${question.params.choice_routing_tree_node}`;
          const deleteNextTreeNode = `/api/tree_nodes/${question.params.delete_next_tree_node}`;
          if (state.ids.includes(deleteNextTreeNode)) {
            state.ids = state.ids.filter((id) => id !== deleteNextTreeNode);
            delete state.entities[deleteNextTreeNode];
          }
          if (state.ids.includes(choiceRoutingTreeNode)) {
            state.entities[choiceRoutingTreeNode].choiceRoutings  = state.entities[choiceRoutingTreeNode].choiceRoutings.filter(routing => routing['@id'] !==  deleteChoiceRouting);
          }
        }
      }));

    },
  }
})

export const {updateTreeNodes} = treeNodesSlice.actions

export default treeNodesSlice.reducer

// Rename the exports for readability in component usage
export const {
  selectById: selectTreeNodeById,
  selectIds: selectTreeNodeIds,
  selectEntities: selectTreeNodeEntities,
  selectAll: selectAllTreeNodes,
  selectTotal: selectTotalTreeNodes
} = treeNodesAdapter.getSelectors(state => state.treeNodes)

export const selectTreeNodesByTreeId = ({id, hideInvisible = false}) =>
  createSelector(
    [
      state => selectTreeById(state, id), // select the current Tree
      state => state.treeNodes.ids.map(id => state.treeNodes.entities[id]) // this is the same as selectAllComments
    ],
    (tree, treeNodes) => {
      if (tree === undefined || tree.hasOwnProperty('treeNodes') === false) {
        return []
      }

      treeNodes.sort((a, b) => a.sortOrder - b.sortOrder)

      if (hideInvisible) {
        treeNodes = treeNodes.filter(_treeNode => _treeNode.visible)
      }

      // return the treeNodes for the given tree only
      return Object.keys(treeNodes)
        .map(tn => treeNodes[tn])
        .filter(tn => tree.treeNodes.map(tn => tn['@id']).includes(tn['@id']));
    }
  );

export const selectNodeFromTreeNode = uri =>
  createSelector(
    [
      state => selectTreeNodeById(state, uri),
      state => state.nodes.ids.map(id => state.nodes.entities[id]), // this is the same as selectAllNodes
    ],
    (treeNode, nodes) => {
      if (treeNode === undefined || !treeNode?.node) {
        return undefined
      }

      return nodes.find(_item => _item['@id'] === treeNode.node)
    }
  )
