import {logo} from './logo'
import {cibGumtree, cil3d, cilAccountLogout, cilApplicationsSettings, cilArrowBottom, cilArrowTop, cilChevronCircleRightAlt, cilDescription, cilLockLocked, cilMoon, cilMove, cilSitemap, cilSun, cilUser, cilX, cilPaperclip} from '@coreui/icons'
import {cidXCircle, cilExitToApp, cisCaretBottom, cisCaretTop, cisGripDotsVertical, cisOptions, cisSettingsAlt, cisTrash, cisUser, cilNote, cisPlus, cisFlagAlt, cisNotes, cisCheckCircle, cisWarning, cisExitToApp, cidSwapVerticalCircle, cisExternalLink, cisCommentBubbleLines, cilExclamation, cilTimeline,  cilEye, cilArrowCircleLeft, cisSync} from '@coreui/icons-pro'

export const icons = Object.assign({}, {
  logo
}, {
  cilApplicationsSettings,
  cilSun,
  cilMoon,
  cilChevronCircleRightAlt,
  cil3d,
  cilSitemap,
  cilDescription,
  cilMove,
  cilX,
  cidXCircle,
  cisPlus,
  cisGripDotsVertical,
  cilUser,
  cilLockLocked,
  cisUser,
  cilAccountLogout,
  cisSettingsAlt,
  cilExclamation,
  cilTimeline,
  cilArrowCircleLeft,
  cisSync,
  cilEye,
  cilArrowTop,
  cilArrowBottom,
  cisTrash,
  cisCaretTop,
  cisCaretBottom,
  cibGumtree,
  cilExitToApp,
  cisOptions,
  cilNote,
  cisFlagAlt,
  cisNotes,
  cisCheckCircle,
  cisWarning,
  cisExitToApp,
  cidSwapVerticalCircle,
  cilPaperclip,
  cisExternalLink,
  cisCommentBubbleLines,
})
