import {createAsyncThunk, createEntityAdapter, createSelector, createSlice} from '@reduxjs/toolkit'
import {normalize} from "normalizr";
import {treeEntity, treeNodeEntity, treePublication} from "../../schemas";
import treeAPI from "../../services/treeAPI";

// Use the uri as ID
const treesAdapter = createEntityAdapter()

export const fetchTrees = createAsyncThunk("trees/fetchTrees", async () => {
    const results = await treeAPI.list();
    const normalized = normalize(results.data['hydra:member'], [treeEntity]);

    return normalized.entities;
  }
)

export const getSubtreeStructure = createAsyncThunk("trees/getSubtreeStructure", async (id) => {
    const results = await treeAPI.listSubtrees(id);
    return results.data;
  }
)

export const getComprehensiveFlowAutoComplete = createAsyncThunk("trees/getComprehensiveFlowAutoComplete", async ({id}) => {
    const results = await treeAPI.getComprehensiveFlowAutocomplete(id);
    return results.data;
  }
)

export const getTree = createAsyncThunk("trees/getTree", async (id) => {
    const results = await treeAPI.get(id);
    const normalized = normalize(results.data, treeEntity);

    return normalized.entities;
  }
)

export const validateTree = createAsyncThunk("trees/validateTree", async (id) => {
    const results = await treeAPI.validate(id);
    const normalized = normalize(results.data, treeEntity);

    return normalized.entities;
  }
)

export const createTree = createAsyncThunk("trees/createTree", async ({formData}) => {
    const results = await treeAPI.create(formData);
    const normalized = normalize(results.data, treeEntity);

    return normalized.entities;
  }
)

export const patchTree = createAsyncThunk("trees/patchTree", async ({uri, formData}) => {
    const results = await treeAPI.patch(uri, formData);
    const normalized = normalize(results.data, treeEntity);

    return normalized.entities;
  }
)
export const rollbackTree = createAsyncThunk("trees/rollbackTree", async ({id, formData}) => {
    const results = await treeAPI.rollback(id, formData);
    const normalized = normalize(results.data, treeEntity);

    return normalized.entities;
  }
)

export const deleteTree = createAsyncThunk("trees/deleteTree", async ({tree}) => {
    await treeAPI.delete(tree['@id']);

    return tree
  }
)

export const publishTree = createAsyncThunk("trees/publishTree", async ({uri}) => {
    const results = await treeAPI.publish(uri);
    const normalized = normalize(results.data, treePublication);

    return normalized.entities;
  }
)

export const previewTree = createAsyncThunk("trees/previewTree", async ({uri, params}) => {
    const results = await treeAPI.preview(uri, params);
    const normalized = normalize(results.data, treePublication);

    return normalized.entities;
  }
)

export const addNode = createAsyncThunk("trees/addNode", async ({tree, node, visible = true}) => {
    const results = await treeAPI.addNode({tree, node, visible});
    const normalized = normalize(results.data, treeNodeEntity);

    return normalized.entities;
  }
)

export const deleteTreeNode = createAsyncThunk("trees/deleteTreeNode", async (payload) => {
    const {treeNode} = payload
    await treeAPI.delete(treeNode['@id']);

    return payload
  }
)

const treesSlice = createSlice({
  name: 'trees',
  initialState: treesAdapter.getInitialState(),
  reducers: {},
  extraReducers: {
    [addNode.fulfilled]: (state, action) => {
      const treeNodes = Object.values(action.payload.treeNodes)
      const treeNode = treeNodes[0]

      const tree = Object.values(state.entities).find((tree) => tree['@id'] === treeNode.tree)

      if (tree.hasOwnProperty('treeNodes')) {
        tree.treeNodes.push(treeNode)
      } else {
        tree.treeNodes = [treeNode]
      }
    },
    [fetchTrees.fulfilled]: (state, action) => {
      treesAdapter.upsertMany(state, action.payload.trees ?? [])
    },
    [patchTree.fulfilled]: (state, action) => {
      treesAdapter.upsertMany(state, action.payload.trees ?? [])
    },
    [patchTree.rejected]: (state, action) => {
      console.error('trees/patchTree could cause error if so createAsyncThunk putTree for this call');
    },
    [createTree.fulfilled]: (state, action) => {
      treesAdapter.addMany(state, action.payload.trees)
    },
    [getTree.fulfilled]: (state, action) => {
      treesAdapter.upsertMany(state, action.payload.trees)
    },
    [validateTree.fulfilled]: (state, action) => {
      treesAdapter.upsertMany(state, action.payload.trees)
    },
    [deleteTreeNode.fulfilled]: (state, action) => {
      const {tree, treeNode} = action.payload;
      const deletedTreeNodeId = treeNode.id

      state.entities[tree.id].treeNodes = state.entities[tree.id].treeNodes.filter(treeNode => {
        return treeNode.id !== deletedTreeNodeId
      })
    },
    [deleteTree.fulfilled]: (state, action) => {
      treesAdapter.removeOne(state, action.payload.id)
    },
  }
})

// export const {} = treesSlice.actions

export default treesSlice.reducer

// Rename the exports for readability in component usage
export const {
  selectById: selectTreeById,
  selectIds: selectTreeIds,
  selectEntities: selectTreeEntities,
  selectAll: selectAllTrees,
  selectTotal: selectTotalTrees
} = treesAdapter.getSelectors(state => state.trees)

export const selectTreeByUri = uri => createSelector(
  (state) => state.trees.entities,
  (trees) => Object.values(trees).find((tree) => tree['@id'] === uri)
)

export const getTreeById = (id) => createSelector(
  (state) => state.trees.entities,
  (trees) => {
    return Object.values(trees).find((tree) => tree.id === id)
  }
)
