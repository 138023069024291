import {createAsyncThunk, createEntityAdapter, createSelector, createSlice} from '@reduxjs/toolkit'
import {normalize} from "normalizr";
import {questionEntity} from "../../schemas";
import questionAPI from "../../services/questionAPI";
import {selectAllNodes, selectNodeById} from "../nodes/nodesSlice";
import hash from "hash-sum";
import _ from "lodash";

// Use the uri as ID
const questionsAdapter = createEntityAdapter({
  selectId: entity => entity['@id']
})

export const fetchQuestions = createAsyncThunk(
  "questions/fetchQuestions",
  async () => {
    const results = await questionAPI.list();
    const normalized = normalize(results.data['hydra:member'], [questionEntity]);

    return normalized.entities;
  }
)

export const createQuestion = createAsyncThunk("questions/createQuestion", async (formData) => {
    const results = await questionAPI.create(formData);
    const normalized = normalize(results.data, questionEntity);

    return normalized.entities;
  }
)

export const patchQuestion = createAsyncThunk("questions/patchQuestion", async ({uri, formData}) => {
    if (formData.hasOwnProperty('choices')) {
      formData = {
        ...formData,
        choices: formData.choices.map((choice, index) => {
          return {
            ...choice,
            id: choice['@id'],
            sortOrder: index,
          }
        })
      }
    }

    formData.hasOwnProperty('@id') && delete formData['@id']
    formData.hasOwnProperty('id') && delete formData['id']

    if (formData.hasOwnProperty('skipChoice') && formData.skipChoice) {
      const newSkipChoice = {
        ...formData.skipChoice,
      }
      if (newSkipChoice.hasOwnProperty('@id')) {
        newSkipChoice.id = newSkipChoice['@id']
      }

      formData.skipChoice = newSkipChoice
    }

    if (formData.hasOwnProperty('rules') && formData.rules) {
      formData = {
        ...formData,
        rules: formData.rules.map(_rule => {
          const rule = {
            ..._rule,
          }
          if (rule.hasOwnProperty('@id')) {
            rule.id = rule['@id']
          }

          return rule
        })
      }
    }

    const results = await questionAPI.put(uri, formData);
    const normalized = normalize(results.data, questionEntity);

    return normalized.entities;
  }
)

export const getQuestion = createAsyncThunk("questions/getQuestion", async ({uri}) => {
    const results = await questionAPI.get(uri);
    const normalized = normalize(results.data, questionEntity);

    return normalized.entities;
  }
)

const questionsSlice = createSlice({
  name: 'questions',
  initialState: questionsAdapter.getInitialState(),
  reducers: {},
  extraReducers: {
    [fetchQuestions.fulfilled]: (state, action) => {
      questionsAdapter.upsertMany(state, action.payload.questions ?? [])
    },
    [createQuestion.fulfilled]: (state, action) => {
      questionsAdapter.addMany(state, action.payload.questions)
    },
    [getQuestion.fulfilled]: (state, action) => {
      questionsAdapter.upsertMany(state, action.payload.questions)
    },
    [patchQuestion.fulfilled]: (state, action) => {
      questionsAdapter.upsertMany(state, action.payload.questions)
    },
    'choices/addChoice/fulfilled': (state, action) => {
      const choices = Object.values(action.payload.choices)
      const newChoice = choices[0]

      state.entities[newChoice.choiceQuestion].choices.push(newChoice)
    }
  }
})

// export const {} = questionsSlice.actions

export default questionsSlice.reducer

// Rename the exports for readability in component usage
export const {
  selectById: selectQuestionById,
  selectIds: selectQuestionIds,
  selectEntities: selectQuestionEntities,
  selectAll: selectAllQuestions,
  selectTotal: selectTotalQuestions
} = questionsAdapter.getSelectors(state => state.questions)

export const selectQuestionsFromNode = nodeId =>
  createSelector(
    [
      state => selectNodeById(state, nodeId),
      state => selectQuestionEntities(state),
    ],
    (node, questions) => {
      return node.questions.map(_questionUri => questions[_questionUri])
    }
  )

export const selectAllQuestionsFromTree = (tree = {}, indexByHash = false) =>
  createSelector(
    [
      state => selectAllNodes(state),
      state => selectQuestionEntities(state),
    ],
    (allNodes, allQuestions) => {
      let nodeQuestionIds = []
      const {treeNodes} = tree

      treeNodes.forEach(_treeNode => {
        const node = _.find(allNodes, ['@id', _treeNode.node])

        if (node) {
          nodeQuestionIds = nodeQuestionIds.concat(node?.questions ?? [])
        }
      })

      const treeQuestions = Object.values(allQuestions)
        .filter(_question => nodeQuestionIds.includes(_question['@id']))

      if (indexByHash) {
        const questions = {}

        treeQuestions.forEach(_question => {
          questions['#' + hash(_question.id)] = _question
        })

        return questions
      }

      return treeQuestions
    }
  )
