import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Login from "./Login";
import {handleTokenRefresh} from "../../features/security/securitySlice";

function Authentication(props) {
  const {isLoggedIn} = useSelector(state => state.security);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true)

  if (!isLoggedIn && loading) {
    dispatch(handleTokenRefresh()).then(() => {
      setLoading(false)
    })
  }

  if (loading) {
    return <>loading auth</>
  }

  if (!loading && !isLoggedIn) {
    return <Login />
  }

  return props.children
}

export default Authentication
