import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import baseAPI from "../../services/baseAPI";

export const checkOrphanedEntities = createAsyncThunk("app/checkOrphanedEntities", async (id) => {
    const results = await baseAPI.post('/api/check_orphaned_entities')
    return results.data;
  }
)
export const cleanOrphanedEntities = createAsyncThunk("app/cleanOrphanedEntities", async (id) => {
    const results = await baseAPI.post('/api/clean_up_orphaned_entities')
    return results.data;
  }
)
export const cleanMetadata = createAsyncThunk("app/cleanMetadata", async (id) => {
    const results = await baseAPI.post('/api/clean_metadata')
    return results.data;
  }
)
export const checkMetadata = createAsyncThunk("app/checkMetadata", async (id) => {
    const results = await baseAPI.post('/api/check_metadata')
    return results.data;
  }
)
const appSlice = createSlice({
  name: 'app',
  initialState: {
    sidebarShow: 'responsive',
    asideShow: false,
    darkMode: false,
    showAddNodeModal: false,
    nodeToEdit: false,
    entity: false,
    metaOrigin: null,
    saveInProgress: false,
    isSaved: false,
  },
  reducers: {
    set: {
      reducer(state, action) {
        const {key, value} = action.payload;
        state[key] = value;
      },
    },
    editEntityProperties: {
      reducer(state, action) {
        state.entity = action.payload.entity
        state.metaOrigin = action.payload.metaOrigin
        state.asideShow = true
      },
    }
  }
})

export const {editEntityProperties, set} = appSlice.actions

export default appSlice.reducer
