import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {Alert, Button, Card, Col, Container, InputGroup, Row} from "react-bootstrap";
import CIcon from '@coreui/icons-react'
import {Field, Form, Formik} from 'formik';
import {doLogin} from "../../features/security/securitySlice";
import * as Yup from 'yup';
import classNames from "classnames";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required(),
  password: Yup.string().required(),
});

function Login() {
  const dispatch = useDispatch();
  const [loginFailed, setLoginFailed] = useState(false)

  function handleSubmit(values) {
    return new Promise((resolve, reject) => {
      dispatch(doLogin(values)).then(action => {
        if (action.type === 'security/doLogin/rejected') {
          reject()
          return
        }

        resolve()
      })
    })
  }

  return <div className="c-app c-default-layout flex-row align-items-center body-login">
    <Container>
      <Row className="justify-content-center">
        <Col md={8} lg={5} xl={4}>
          <Card className="p-4">
            <Card.Body className="card-body-login">
              <img alt="" className="logo-login" height="30px" src="color-logo.svg"/>

              <h1 className="font-weight-bolder login-header">Login</h1>

              <Formik
                initialValues={{
                  email: '',
                  password: '',
                }}
                validationSchema={LoginSchema}
                onSubmit={(values, {setSubmitting}) => {
                  handleSubmit(values).then((action) => {
                    setLoginFailed(false)
                    setSubmitting(false);
                  }).catch(() => {
                    setLoginFailed(true)
                    setSubmitting(false);
                  })
                }}
              >
                {({errors, touched, isSubmitting}) => (
                  <Form>
                    {loginFailed && <Alert variant="danger" dismissible onClose={() => setLoginFailed(false)}>Login failed!</Alert>}
                    <InputGroup className="mb-3">
                      <div className="input-group-prepend">
                        <div className="input-group-text"><CIcon name="cil-user" /></div>
                      </div>
                      <Field className={classNames('form-control', {'is-invalid': errors.email && touched.email})} id="email" name="email" placeholder="user@company.com" type="email" />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <div className="input-group-prepend">
                        <div className="input-group-text"><CIcon name="cil-lock-locked" /></div>
                      </div>
                      <Field className={classNames('form-control', {'is-invalid': errors.password && touched.password})} id="password" name="password" placeholder="Password" type="password" />
                    </InputGroup>

                    <Row>
                      <Col>
                        <Button className="px-4 login-button" type="submit" disabled={isSubmitting}>Login</Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </div>
}

export default Login
